import React from "react";
import classes from "./Publications.module.scss";
import LoanManagementBook from "../../assets/PublicationsImages/LoanManagement_book.png";
import digitalBooks from "../../assets/PublicationsImages/digitilizeBook.png";

function PublicationsBooks() {
  let publicationsBooksObject = [
    {
      paragraph:
        "Automated Loan Management software is be- coming a mandatory investment for today’s fi- nancial companies and banks. Loan and lending organizations face a series of critical tasks each day, from managing cash flow to checking loan eligibility and enforcing full and timely collection.",
      Image: LoanManagementBook,
      pdfPath: require("../../assets/pdfFiles/AutomatedLoanManagement.pdf"),
      filename: 'How Automated Loan Management System Accelerate Business Book'
    },
    {
      paragraph:
        "Africa’s gaming industry, encompassing casinos,sports betting, lotteries, and online gaming, is undergoing a rapid transformation driven by digitalization. With increased access to mobile phones and internet connectivity, gaming is becoming more accessible to millions of Africans.",
      Image: digitalBooks,
      pdfPath: require("../../assets/pdfFiles/Digitalization.pdf"),
      filename: 'Digitalization in Africas Gaming Industry Book'
    },
  ];


  const handleDownload = (pdfPath,filename) => {
    const link = document.createElement('a');
    link.href = pdfPath;
    link.download = filename; 
    link.click();
  };

  return (
    <div className={classes.booksWrapper}>
      {publicationsBooksObject &&
        publicationsBooksObject.map((item, index) => {
          return (
            <div key={index} className={classes.squareParagraph}>
              <div className={classes.bookSquare}>
                <img
                  src={item.Image}
                  alt="publications Books"
                  className={classes.imageInside}
                />
              </div>
              <p className={classes.paragraphStyle}>{item.paragraph}</p>
              <button className={classes.btnStyle} onClick={() => handleDownload(item.pdfPath,item.filename)}
              >Read More</button>
            </div>
          );
        })}
    </div>
  );
}

export default PublicationsBooks;
