import React from "react";
import classes from "./Publications.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PromoBanner from "../../components/PromoBanner/PromoBanner";
import MoobiBanners from "../../components/MoobiBanners/MoobiBanners";
import Publicationsname from "../../assets/PublicationsImages/Publications_name.svg";
import PublicationsBooks from "./PublicationsBooks";

function Publications() {
  return (
    <div >
      <Header />
      <MoobiBanners logoName={Publicationsname} />
      <div className="container">
        <div className={classes.detailsPublication}>
        <div className={classes.infoContainer}>
          <div className={classes.firstInfoCont}>
            <span className={classes.titlePublication}>
              Over<span className={classes.titleSecondPublication}>view</span>
            </span>
          </div>
          <p className={classes.paragraphPublication}>
            At Moobitek we are committed to driving innovation and sharing
            expertise in fintech, iGaming, and payment technologies. Our
            publications provide in-depth insights into digital banking, BNPL,
            secure payment solutions, and the latest trends in online gaming.
            From thought leadership articles and research papers to E-books
            showcasing real-world success, we offer valuable perspectives on how
            technology is transforming these industries. Stay informed with our
            updates to understand market trends, regulatory changes, and the
            future of fintech, iGaming, and payment solutions.
          </p>
        </div>
        <PublicationsBooks />
        </div>
      </div>
      <PromoBanner />
      <Footer />
    </div>
  );
}

export default Publications;
